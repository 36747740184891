import { ActionTree } from 'vuex';
import {
  IBoostState,
  IProjectBoostStats,
  IUserBoostHoldRank,
  IProjectBoostItem,
  IUserBoostHold,
  IFilmBoostSupporter,
  IUpsertBoostItemAndGrantLockAllowanceRequest,
  EUnlockType,
  INormalizedChainBalance,
} from './types';
import { IRootState } from '../types';

export const actions: ActionTree<IBoostState, IRootState> = {
  async getBoostsForProjects({ commit, dispatch, state }, payload: {}) {
    try {
      const res = await this.$filmApiService.getBoostsForProjects();
      const items: Array<{
        project: IProjectBoostItem;
        userHold: IUserBoostHold;
      }> = res?.data?.items || [];

      items.forEach((item) => {
        const { project, userHold } = item || {};
        commit('updateProjectBoost', { data: project });
        commit('updateUserBoostHold', { data: userHold });
      });
    } catch (error) {
      console.warn('Error fetching boosts for projects', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async getProjectFilmBoostStats(
    { commit, dispatch, state },
    payload: { category: string },
  ) {
    try {
      const res = await this.$filmApiService.getProjectFilmBoostStats(
        payload.category,
      );
      const data: {
        project: IProjectBoostStats | null;
        userHold: IUserBoostHoldRank;
      } = res?.data;
      commit('updateProjectBoost', { data: data?.project });
      commit('updateUserBoostHold', { data: data?.userHold });
    } catch (error) {
      console.warn('Error fetching boost stats', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async getSupporters(
    { commit, dispatch, state },
    payload: { category: string },
  ) {
    try {
      const res = await this.$filmApiService.getSupporters(payload.category);
      const userHolds: IFilmBoostSupporter[] = res?.data?.users || [];
      commit('updateBoostSupporters', {
        userHolds,
        projectSlug: payload.category,
      });
    } catch (error) {
      console.warn('Error fetching supporters', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async getUsersFilmBoostHold(
    { commit, dispatch, state },
    payload: { category: string },
  ) {
    try {
      const res = await this.$filmApiService.getUsersFilmBoostHold(
        payload.category,
      );
      const data: IUserBoostHold = res?.data?.userHold || {};
      commit('updateUserBoostHold', { data });
    } catch (error) {
      console.warn('Error fetching user film boost hold', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async placeLock(
    { commit, dispatch, state },
    payload: IUpsertBoostItemAndGrantLockAllowanceRequest,
  ) {
    try {
      const res =
        await this.$filmApiService.upsertFilmBoostItemAndGrantLockAllowance(
          payload,
        );
      const data: IUserBoostHold = res?.data?.userHold || {};
      commit('updateUserBoostHold', { data });
      return res;
    } catch (error) {
      console.warn('Error placing lock', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async requestHold(
    { commit, dispatch, state },
    payload: { projectSlug: string; holdingPeriodUnlockAmount: number },
  ) {
    try {
      const res = await this.$filmApiService.requestFilmBoostHold({
        projectSlug: payload.projectSlug,
        holdingPeriodUnlockAmount: payload.holdingPeriodUnlockAmount,
      });
      const data: IUserBoostHold = res?.data?.userHold || {};
      commit('updateUserBoostHold', { data });
      return res;
    } catch (error) {
      console.warn('Error requesting hold for unlock', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async cancelHold(
    { commit, dispatch, state },
    payload: { projectSlug: string },
  ) {
    try {
      const res = await this.$filmApiService.cancelFilmBoostHold({
        projectSlug: payload.projectSlug,
      });
      const data: IUserBoostHold = res?.data?.userHold || {};
      commit('updateUserBoostHold', { data });
      return res;
    } catch (error) {
      console.warn('Error canceling hold for unlock', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async unlockFilmBoost(
    { commit, dispatch, state },
    payload: {
      projectSlug: string;
      amount: number;
      unlockType: EUnlockType;
      transferCode: string;
    },
  ) {
    try {
      const res = await this.$filmApiService.upsertFilmBoostItemUnlock({
        projectSlug: payload.projectSlug,
        quantity: payload.amount,
        gyriId: 'FILM|Unit|none|none|0',
        unlockType: payload.unlockType,
        transferCode: payload.transferCode,
      });
      const data: IUserBoostHold = res?.data?.userHold || {};
      commit('updateUserBoostHold', { data });
      return res;
    } catch (error) {
      console.warn('Error canceling hold for unlock', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },

  async fetchTokenBalance({ commit, dispatch, state }) {
    try {
      const res = await this.$filmApiService.fetchTokenBalance();
      const balance: INormalizedChainBalance = res?.data?.balance || {};
      commit('updateUserBalance', { balance });
      return res;
    } catch (error) {
      console.warn('Error retrieving token balance', error);
      this.$sentry.captureException(error);
      throw error;
    }
  },
};
