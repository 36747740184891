import { MutationTree } from 'vuex';
import { ICollectionState } from './types';
import {
  IPoster,
  IPrize,
  IMysteryBox,
  IScene,
  IPack,
  ICollectible,
} from '~/types/collections';
import _startCase from 'lodash.startcase';
import {
  IFilmCollectionOverview,
  ISceneStats,
  IFilmMomentStatsOverview,
  IUserRewardsDistribution,
  IUserPopcornBuckets,
} from '~/types/my-collection';
import { IGyriUserItem } from '~/types/user-items';
import { getUniqueInventoryPath } from '~/utils/userItem';
import { getMergedPosterMap, getMergedSceneMap } from '~/utils/collections';
import { ISet } from '~/store/customReveal/types';
import { deepSet } from '~/store/store-utils';

export const mutations: MutationTree<ICollectionState> = {
  updateLoadedProjectCollection(
    collectionState: ICollectionState,
    payload: { projectSlug: string },
  ) {
    if (payload.projectSlug) {
      deepSet(
        collectionState,
        `byProject.${payload.projectSlug}.loadedCollectionData`,
        true,
      );
    }
  },

  updatedLoadededAllCollections(collectionState: ICollectionState) {
    deepSet(collectionState, `loadedAllCollections`, true);
  },

  updateProjectItems(
    collectionState: ICollectionState,
    payload: { items: IGyriUserItem[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.items`,
            payload.items ?? [],
          );
        },
      );
    }
  },

  updateMiscItems(
    collectionState: ICollectionState,
    payload: { items: IGyriUserItem[] },
  ) {
    if (payload) {
      collectionState.miscItems = (payload.items ?? []).map((item) => ({
        ...item,
        uniqueInventoryPath: getUniqueInventoryPath(item),
      }));
    }
  },

  updateFilmItems(
    collectionState: ICollectionState,
    payload: { items: IGyriUserItem[]; projectSlug: string },
  ) {
    if (payload) {
      collectionState.filmItems = payload.items ?? [];
    }
  },

  updateProjectStats(
    collectionState: ICollectionState,
    payload: {
      projectStats: IFilmCollectionOverview;
      projectSlug: string;
    },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          const currentStats =
            collectionState.byProject[payload.projectSlug]
              .projectStatsOverview || {};
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.projectStatsOverview`,
            { ...currentStats, ...(payload.projectStats || {}) },
          );
        },
      );
    }
  },

  updatePosters(
    collectionState: ICollectionState,
    payload: { posters: IPoster[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          const project = collectionState.byProject[payload.projectSlug];
          const currentPosters = project.posters || [];
          const newPosters = payload.posters ?? [];
          const mergedPosterMap = getMergedPosterMap(
            currentPosters,
            newPosters,
          );
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.posters`,
            newPosters.map(
              (poster) => mergedPosterMap[poster.posterId] || poster,
            ),
          );
        },
      );
    }
  },

  updatePrizes(
    collectionState: ICollectionState,
    payload: { prizes: IPrize[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.prizes`,
            payload.prizes ?? [],
          );
        },
      );
    }
  },

  updateMysteryBoxes(
    collectionState: ICollectionState,
    payload: { mysteryBoxes: IMysteryBox[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.mysteryBoxes`,
            payload.mysteryBoxes ?? [],
          );
        },
      );
    }
  },

  updateMomentPacks(
    collectionState: ICollectionState,
    payload: { momentPacks: IPack[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.momentPacks`,
            payload.momentPacks ?? [],
          );
        },
      );
    }
  },

  updateCollectibles(
    collectionState: ICollectionState,
    payload: { collectibles: ICollectible[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.collectibles`,
            payload.collectibles ?? [],
          );
        },
      );
    }
  },

  updateScenes(
    collectionState: ICollectionState,
    payload: { scenes: IScene[]; projectSlug: string },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
        },
        () => {
          const project = collectionState.byProject[payload.projectSlug];
          const currentScenes = project.scenes || [];
          const newScenes = payload.scenes || [];
          const mergedSceneMap = getMergedSceneMap(currentScenes, newScenes);
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.scenes`,
            newScenes.map((scene) => mergedSceneMap[scene.sceneSlug] || scene),
          );
        },
      );
    }
  },

  // updates rewards for A scene and ITS moments
  updateSceneStats(
    collectionState: ICollectionState,
    payload: {
      sceneStats: ISceneStats;
      sceneSlug: string;
      projectSlug: string;
    },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
          sceneSlug: payload.sceneSlug,
        },
        () => {
          const currentStats =
            collectionState.byProject[payload.projectSlug].byScene[
              payload.sceneSlug
            ] || {};
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.byScene.${payload.sceneSlug}`,
            { ...currentStats, ...(payload.sceneStats || {}) },
          );
        },
      );
    }
  },

  updateMomentStats(
    collectionState: ICollectionState,
    payload: {
      momentStats: IFilmMomentStatsOverview;
      momentId: string;
      projectSlug: string;
    },
  ) {
    if (payload) {
      updateState(
        collectionState,
        {
          projectSlug: payload.projectSlug,
          momentId: payload.momentId,
        },
        () => {
          const currentStats =
            collectionState.byProject[payload.projectSlug].byMoment[
              payload.momentId
            ] || {};
          deepSet(
            collectionState,
            `byProject.${payload.projectSlug}.byMoment.${payload.momentId}`,
            { ...currentStats, ...(payload.momentStats || {}) },
          );
        },
      );
    }
  },

  refreshProject(
    collectionState: ICollectionState,
    payload: { projectSlug: string },
  ) {
    if (payload) {
      updateState(collectionState, { projectSlug: payload.projectSlug }, () => {
        deepSet(
          collectionState,
          `byProject.${payload.projectSlug}`,
          JSON.parse(
            JSON.stringify(
              collectionState.byProject[payload.projectSlug] ?? {},
            ),
          ),
        );
      });
    }
  },

  updateUserRewardsDistribution(
    collectionState: ICollectionState,
    payload: { rewards: IUserRewardsDistribution[] },
  ) {
    if (payload) {
      collectionState.rewardDistrubutions = payload.rewards;
    }
  },

  updateUserPopcornBuckets(
    collectionState: ICollectionState,
    payload: { buckets: IUserPopcornBuckets[] },
  ) {
    if (payload) {
      collectionState.popcornBuckets = payload.buckets;
    }
  },

  updateUserTotalRewards(
    collectionState: ICollectionState,
    payload: { totalRewards: number },
  ) {
    if (payload) {
      collectionState.userTotalRewards = payload.totalRewards;
    }
  },

  updateUserLastDayRewards(
    collectionState: ICollectionState,
    payload: { lastDayRewards: number },
  ) {
    if (payload) {
      collectionState.userLastDayRewards = payload.lastDayRewards;
    }
  },

  updatePoster(
    collectionState: ICollectionState,
    payload: { project: string; posterSlug: string; poster: Partial<IPoster> },
  ) {
    const { project, posterSlug, poster } = payload || {};
    if (project && posterSlug && poster) {
      updateState(
        collectionState,
        {
          projectSlug: project,
        },
        () => {
          const projectPosters =
            collectionState.byProject[project]?.posters || [];
          const posterIndex = projectPosters.findIndex(
            (p) => p.posterId === posterSlug,
          );

          if (posterIndex !== -1) {
            const currentPoster = projectPosters[posterIndex];
            deepSet(
              collectionState,
              `byProject.${project}.posters.${posterIndex}`,
              { ...currentPoster, ...poster },
            );
          }
        },
      );
    }
  },

  updateScene(
    collectionState: ICollectionState,
    payload: { project: string; sceneSlug: string; scene: Partial<IScene> },
  ) {
    const { project, sceneSlug, scene } = payload || {};
    if (project && sceneSlug && scene) {
      updateState(
        collectionState,
        {
          projectSlug: project,
        },
        () => {
          const projectScenes =
            collectionState.byProject[project]?.scenes || [];
          const sceneIndex = projectScenes.findIndex(
            (s) => s.sceneSlug === sceneSlug,
          );
          if (sceneIndex !== -1) {
            const currentScene = projectScenes[sceneIndex];
            deepSet(
              collectionState,
              `byProject.${project}.scenes.${sceneIndex}`,
              { ...currentScene, ...scene },
            );
          }
        },
      );
    }
  },

  // todo: once we use other unopened box getter
  updateUnopenedBoxes(
    collectionState: ICollectionState,
    payload: { unopenedBoxes: Record<string, ISet[]> },
  ) {
    const { unopenedBoxes } = payload || {};
    if (unopenedBoxes) {
      collectionState.unopenedBoxes = unopenedBoxes;
    }
  },
};

function updateState(
  collectionState: ICollectionState,
  params: {
    projectSlug: string;
    sceneSlug?: string;
    momentId?: string;
  },
  update: () => void,
): void {
  // NOTE: this is a hack to initialize the structure for project, scene, moment if not there

  if (params.projectSlug && !collectionState.byProject[params.projectSlug]) {
    initializeProjectStruct(collectionState, params);
  }

  if (
    params.projectSlug &&
    params.sceneSlug &&
    !collectionState?.byProject?.[params.projectSlug]?.byScene?.[
      params.sceneSlug
    ]
  ) {
    initializeSceneStruct(collectionState, params);
  }

  if (
    params.projectSlug &&
    params.momentId &&
    !collectionState?.byProject?.[params.projectSlug]?.byMoment?.[
      params.momentId
    ]
  ) {
    initializeMomentStruct(collectionState, params);
  }

  update?.();
}

function initializeProjectStruct(
  collectionState: ICollectionState,
  params: {
    projectSlug: string;
    sceneSlug?: string;
    momentId?: string;
  },
): void {
  const initProject = {
    projectStatsOverview: {
      lastDayRewards: 0,
      totalRewards: 0,
    },
    byScene: {},
    byMoment: {},
    scenes: [],
    posters: [],

    items: [],
    prizes: [],
    mysteryBoxes: [],
    momentPacks: [],
    collectibles: [],
  };
  deepSet(collectionState, `byProject.${params.projectSlug}`, initProject);
}

function initializeSceneStruct(
  collectionState: ICollectionState,
  params: {
    projectSlug: string;
    sceneSlug?: string;
    momentId?: string;
  },
): void {
  const initScene = {
    distroBoost: 0,
    lastDayRewards: 0,
    totalRewards: 0,
  };
  deepSet(
    collectionState,
    `byProject.${params.projectSlug}.byScene.${params.sceneSlug}`,
    initScene,
  );
}

function initializeMomentStruct(
  collectionState: ICollectionState,
  params: {
    projectSlug: string;
    sceneSlug?: string;
    momentId?: string;
  },
): void {
  const initMoment = {
    lastDayRewards: 0,
    totalRewards: 0,
  };
  deepSet(
    collectionState,
    `byProject.${params.projectSlug}.byMoment.${params.momentId}`,
    initMoment,
  );
}
