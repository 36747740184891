import Vue from 'vue';
import { Line } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  CategoryScale,
  LinearScale,
  LineElement,
  Filler,
);

Vue.component('line-chart', {
  extends: Line,
});

// Vue.component('line-chart', {
// 	extends: Line,
// 	props: ['data', 'options'],
// 	mounted () {
// 		this.renderChart(this.data, this.options)
// 	}
// })

// // import Vue from 'vue'
// // import { Line } from 'vue-chartjs'
// // import { Pie } from 'vue-chartjs'

// // Vue.component('line-chart', {
// // 	extends: Line,
// // 	props: ['data', 'options'],
// // 	mounted () {
// // 		this.renderChart(this.data, this.options)
// // 	}
// // })

// // Vue.component('pie-chart', {
// // 	extends: Pie,
// // 	props: ['data', 'options'],
// // 	mounted () {
// // 		this.renderChart(this.data, this.options)
// // 	}
// // })
