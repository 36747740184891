import { ActionTree } from 'vuex';
import _differenceWith from 'lodash.differencewith';
import _partition from 'lodash.partition';
import {
  ICommitUpdateItemData,
  IExchangeTokenOptions,
  IInventoryState,
  IMintTokenRequest,
  IWallet,
} from './types';
import { IBaseGqlResponse, IRootState } from '../types';
import getWallets from '@/queries/wallets/wallets.gql';
import cryptocompareService from '~/services/cryptocompare.service';
import tokenClaimFeesQuery from '~/queries/tokenClaimFees.gql';
import hasTransferLock from '~/queries/hasTransferLock.gql';
import sendGameItems from '~/mutations/sendGameItems.gql';
import claimTokensMutation from '~/mutations/claimTokens.gql';
import claimTokensV2Mutation from '~/mutations/claimTokensV2.gql';
import claimTokensWithExternalWalletMutation from '~/mutations/claimTokensWithExternalWallet.gql';
import exchangeEthTokenMutation from '~/mutations/exchangeToken.gql';
import exchangeGyriTokenMutation from '~/mutations/exchangeGyriToken.gql';
import bridgeEthTokenMutation from '~/mutations/bridgeEthToken.gql';
import bridgeGyriTokenMutation from '~/mutations/bridgeGyriToken.gql';
import batchBridgeEthTokenMutation from '~/mutations/batchBridgeEthToken.gql';
import fulfillGalaChainItemAllowanceMutation from '~/mutations/fulfillItemAllowance.gql';
import mockInventories from '~/mocks/inventory_items';
import { ChainNetwork, EthereumTokenStandard } from '~/types/chain';
import { UserItem } from '~/types/user-items';
import { ISendItemActionPayload } from '~/types/vuex_payloads/send_item';
import {
  IBatchBridgeItemsActionPayload,
  IBridgeItemActionPayload,
} from '~/types/vuex_payloads/bridge_item';
import { IBridgeCurrencyActionPayload } from '~/types/vuex_payloads/bridge_currency';
import { IFulfillAllowanceActionPayload } from '../../types/vuex_payloads/fulfill_allowance';
// import { getFilmTokenShedule } from '~/store/inventory/data';

// Wallet-server and app-server talk about networks a bit differently. Here we convert from the app-server
// way to the wallet-server way, which is what the frontend likes.
function convertCurrencyNetwork(currency: {
  network: 'ethereum' | 'galachain' | 'gyri';
  isTreasureChest: boolean;
}) {
  if (currency.isTreasureChest) {
    if (currency.network === 'ethereum') {
      return ChainNetwork.ETH_TREASURE_CHEST;
    } else if (
      currency.network === 'galachain' ||
      currency.network === 'gyri'
    ) {
      return ChainNetwork.GYRI_TREASURE_CHEST;
    }
  } else {
    if (currency.network === 'ethereum') {
      return ChainNetwork.ETHEREUM;
    } else if (
      currency.network === 'galachain' ||
      currency.network === 'gyri'
    ) {
      return ChainNetwork.GYRI;
    }
  }
}

export const actions: ActionTree<IInventoryState, IRootState> = {
  async getWalletsData({ commit, dispatch, state }) {
    try {
      if (this.app.apolloProvider && !state.isFetchingWallets) {
        commit('updateIsFetchingWallets', true);
        const client = this.app.apolloProvider.defaultClient;

        // NOTE: this is the same 'getWallets' query used in getWalletData method
        const {
          data: { wallet },
        } = await client.query({
          query: getWallets,
          fetchPolicy: 'no-cache',
        });

        if (wallet) {
          const withIcons = wallet.map((w: any) => {
            const iconSymbol = w.symbol.replace(/\[ETH\]|\[GC\]/i, '');
            let iconImage = '';
            try {
              iconImage =
                w.icon ?? require(`~/assets/logos/${iconSymbol}-icon.png`);
            } catch (err) {}
            return {
              ...w,
              network: convertCurrencyNetwork(w),
              ethereumContractType: w.contractType,
              icon: iconImage,
              hideFiat: [
                'gala',
                'tank',
                'town',
                'mtrm',
                'test',
                'silk',
                'tctestcoin',
                'nodetestcoin',
                '$gmusic',
              ].includes(iconSymbol.toLowerCase()),
            };
          });

          const [galaWallets, otherWallets] = _partition<IWallet>(
            withIcons,
            (walletWithIcon: IWallet) => walletWithIcon.symbol.includes('GALA'),
          );

          commit('updateIsFetchingWallets', false);
          commit('setWalletState', [...galaWallets, ...otherWallets]);
          dispatch('getFiatPrices');
        }
      }
      return;
    } catch (error) {
      this.$sentry.captureException(error);
      commit('updateIsFetchingWallets', false);
      console.warn(error);
    }
  },

  async getWalletData({ commit, dispatch, state }, coinSymbol: string) {
    if (!state.wallets.length) {
      await dispatch('getWalletsData');
    }
    try {
      if (this.app.apolloProvider) {
        commit('updateIsFetchingWallets', true);
        const client = this.app.apolloProvider.defaultClient;

        // NOTE: this is the same 'getWallets' query used in getWalletsData method
        const {
          data: { wallet },
        } = await client.query({
          query: getWallets,
          variables: { coinSymbol },
          fetchPolicy: 'no-cache',
        });
        if (wallet && wallet.length) {
          const [walletToUpdate] = wallet;
          const { symbol } = walletToUpdate;

          const isGala = symbol.includes('GALA');
          const iconSymbol = walletToUpdate.symbol.replace(
            /\[ETH\]|\[GC\]/i,
            '',
          );

          walletToUpdate.network = convertCurrencyNetwork(walletToUpdate);

          if (isGala) {
            walletToUpdate.hideFiat = true;
          } else {
            // TODO: now that we are storing token prices in our database, we should include that data in the query to get wallet data instead of relying on the client hitting cryptocompare's api
            dispatch('getFiatPrices', [symbol]);
          }

          commit('updateIsFetchingWallets', false);
          commit('setWallet', {
            ...walletToUpdate,
            icon: require(`~/assets/logos/${iconSymbol}-icon.png`),
          });
        }
      }
    } catch (error) {
      this.$sentry.captureException(error);
      commit('updateIsFetchingWallets', false);
      console.warn(error);
    }
  },

  async getFiatPrices({ commit, state }, coinSymbols?: string[]) {
    const { wallets } = state;
    const symbols =
      coinSymbols ||
      wallets
        .filter((wallet: IWallet) => wallet.receiveAddress)
        .map((wallet: IWallet) => wallet.symbol);

    if (symbols.length) {
      try {
        const fiatPrices: any = await cryptocompareService.getPrice(
          symbols,
          'USD',
        );

        commit('updateWalletCoinPrices', fiatPrices);
        return fiatPrices;
      } catch (error) {
        console.warn(error);
      }
    }

    return {};
  },

  async getUserItems({ commit, rootState }, collection?: string) {
    // Don't like this solution but didn't find a better way to not re-query
    // the inventory immediately after an item is transferred (where the inventory
    // update is handled via Vuex mutation)
    if (sessionStorage.getItem('skip-next-inventory-query') === 'true') {
      sessionStorage.setItem('skip-next-inventory-query', 'false');
      return;
    }

    if (process.env.mockInventoryName) {
      const mockInventory = mockInventories[process.env.mockInventoryName];
      if (!mockInventories) {
        throw new Error(
          `Could not find ${process.env.mockInventoryName} mock inventory`,
        );
      }

      commit('updateUserItems', mockInventory);
      return 'success';
    }

    try {
      if (rootState.profile?.user.loggedIn) {
        const results = await this.$filmApiService.getWalletItems();
        const metadataForKey = new Map<string, any>(
          results.data.userItemsV3.metadata.map((m: any) => [
            m.metadataLookupKey,
            m,
          ]),
        );

        const hydratedItems = results.data.userItemsV3.items.map(
          (item: any) => {
            const metadata = metadataForKey.get(item.metadataLookupKey);
            return {
              ...item,
              ...metadata,
            };
          },
        );

        commit('updateUserItems', hydratedItems);
        return 'success';
      }

      return 'error';
    } catch (error) {
      return 'error';
    }
  },

  async getTokenClaimFees({ commit }) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.clients.gateway;
      const {
        data: { tokenClaimFees },
      } = await client.query({
        query: tokenClaimFeesQuery,
        fetchPolicy: 'network-only',
      });

      commit('updateClaimFees', tokenClaimFees);
    }
  },

  async claimTokens(
    { commit, state, getters },
    {
      walletPassword,
      selectedItems,
      gasCost,
      transactionFeePrice,
      totpToken,
    }: {
      walletPassword?: string;
      selectedItems: any[];
      gasCost: number;
      transactionFeePrice: string;
      totpToken: string;
    },
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;
      const mutation = !walletPassword
        ? claimTokensWithExternalWalletMutation
        : process.env.w3wConnectionEnabled
        ? claimTokensV2Mutation
        : claimTokensMutation;

      const variables = {
        claimFee: gasCost,
        tokens: selectedItems.map(({ tokenId, claimType, quantity }) => ({
          tokenId,
          quantity: +quantity,
          claimType,
        })),
        walletPassword,
        transactionFeePrice,
        tokenClaimFeeId: getters.tokenClaimFeeId,
        totpToken,
      };

      const res = await client.mutate({
        mutation,
        variables,
      });
      return res;
    }
  },

  async exchangeGyriTokens(
    { commit },
    {
      exchangeId,
      walletPassword,
      tokens,
      totpToken,
    }: {
      exchangeId: number;
      walletPassword: string;
      totpToken: string;
      tokens: Array<{
        collection: string;
        category: string;
        type: string;
        additionalKey: string;
        instance: string;
      }>;
    },
  ) {
    if (!this.app.apolloProvider) {
      return;
    }

    const client = this.app.apolloProvider.defaultClient;

    const res = await client.mutate({
      mutation: exchangeGyriTokenMutation,
      variables: {
        exchangeId,
        exchangeTokens: tokens.map(
          ({ collection, category, type, additionalKey, instance }) => ({
            tokenInstanceKey: {
              collection,
              category,
              type,
              additionalKey,
              instance,
            },
            quantity: '1',
          }),
        ),
        walletPassword,
        totpToken,
      },
    });

    if (res?.data?.exchangeGyriToken) {
      commit('setGyriExchangeRewards', res.data.exchangeGyriToken.rewards);
      commit('setShowGyriExchangeRewardsModal', true, { root: true });
    }

    return {
      exchangeNetwork: ChainNetwork.GYRI,
      response: res?.data?.exchangeGyriToken,
    };
  },

  async exchangeToken(
    { commit, dispatch },
    {
      quantity,
      walletPassword,
      transactionFeePrice,
      expectedPrice,
      totpToken,
      itemToExchange,
    }: IExchangeTokenOptions,
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (itemToExchange.network === ChainNetwork.ETHEREUM) {
        if (
          itemToExchange.ethereumTokenStandard !== EthereumTokenStandard.ERC1155
        ) {
          throw new Error(
            `Unexpected Ethereum exchange token type: ${itemToExchange.ethereumTokenStandard}`,
          );
        }

        if (!itemToExchange.fungible) {
          throw new Error(
            'Non-fungible ERC1155 tokens are not supported for exchange',
          );
        }

        const res = await client.mutate<
          IBaseGqlResponse<
            'exchangeToken',
            {
              paymentData: {
                smartContractAddress: string;
                smartContractAbi: any[];
                orderPaymentMessage: any;
                signedMessage: string;
              };
            }
          >
        >({
          mutation: exchangeEthTokenMutation,
          variables: {
            network: itemToExchange.network,
            contractAddress: itemToExchange.ethereumContractAddress,
            tokenId: itemToExchange.ethereumBaseId,
            quantity,
            walletPassword,
            transactionFeePrice,
            expectedPrice,
            totpToken,
          },
        });

        if (res?.data?.exchangeToken?.success) {
          commit<ICommitUpdateItemData>({
            type: 'updateUserItem',
            itemUniqueInventoryPath: itemToExchange.uniqueInventoryPath ?? '',
            quantity,
            action: 'exchange',
          });
        }

        return {
          exchangeNetwork: ChainNetwork.ETHEREUM,
          response: res?.data?.exchangeToken,
        };
      }

      if (itemToExchange.network === ChainNetwork.GYRI) {
        return dispatch('exchangeGyriTokens', {
          exchangeId: itemToExchange.gyriExchanges[0].id,
          walletPassword,
          totpToken,
          tokens: [
            {
              collection: itemToExchange.gyriTokenClassKey.collection,
              category: itemToExchange.gyriTokenClassKey.category,
              type: itemToExchange.gyriTokenClassKey.type,
              additionalKey: itemToExchange.gyriTokenClassKey.additionalKey,
              instance: itemToExchange.nonFungibleInstanceId,
            },
          ],
        });
      }

      throw new Error(
        `Unsupported exchange token network: ${itemToExchange.network}`,
      );
    }
  },

  async sendItem(
    { commit },
    {
      item,
      address,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
    }: ISendItemActionPayload,
  ) {
    if (!item.sendId) {
      throw new Error(
        `Trying to send an item ${item.uniqueInventoryPath} that doesn't have a sendId`,
      );
    }

    const instanceIds = item.fungible
      ? undefined
      : [item.nonFungibleInstanceId];

    const output = {
      to: address,
      amount: quantity.toString(),
      tokenId: item.sendId,
      instanceIds,
    };

    const contractAddress =
      item.network === ChainNetwork.ETHEREUM
        ? item.ethereumContractAddress
        : undefined;

    const network = {
      network: item.network,
      contractAddress,
    };

    const outputs = [output];

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const res = await client.mutate({
        mutation: sendGameItems,
        variables: {
          outputs,
          walletPassword: encryptionPasscode,
          coinSymbol: 'GALA',
          transactionFeePrice,
          totpToken,
          network,
        },
      });

      if (
        res.data &&
        res.data.sendGameItems &&
        res.data.sendGameItems.success
      ) {
        commit('updateUserItem', {
          itemUniqueInventoryPath: item.uniqueInventoryPath,
          quantity,
          action: 'send',
        });
      }

      return res;
    }
  },

  async bridgeCurrency(
    { commit },
    {
      wallet,
      targetNetwork,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
      destinationChainId,
      feeTicket,
    }: IBridgeCurrencyActionPayload,
  ) {
    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (targetNetwork === ChainNetwork.GYRI) {
        const contractAddress = wallet.contractAddress;

        const res = await client.mutate({
          mutation: bridgeEthTokenMutation,
          variables: {
            contractAddress,
            tokenId: '',
            quantity: Number(quantity),
            totpToken,
            botToken,
            transactionFeePrice,
            walletPassword: encryptionPasscode,
            transactionHash,
            destinationChainId,
          },
        });

        return {
          success: res?.data?.bridgeEthToken?.success ?? false,
          message: res?.data?.bridgeEthToken?.message,
        };
      } else if (targetNetwork === ChainNetwork.ETHEREUM) {
        try {
          const tokenInstance = {
            collection: wallet.tokenId.toUpperCase(),
            category: 'Unit',
            type: 'none',
            additionalKey: 'none',
            instance: '0',
          };
          const res = await client.mutate({
            mutation: bridgeGyriTokenMutation,
            variables: {
              tokenInstance,
              quantity: Number(quantity),
              totpToken,
              botToken,
              walletPassword: encryptionPasscode,
              bridgeFeePrice: transactionFeePrice,
              feeTicket,
            },
          });

          return {
            success: res?.data?.bridgeGyriToken?.success ?? false,
            message: res?.data?.bridgeGyriToken?.message,
          };
        } catch (error) {
          const e = error as Error;
          if (e.message.includes('HIGH_RISK_WALLET')) {
            const message = await this.dispatch(
              'web3Wallet/handleHighRiskWalletError',
            );
            throw new Error(message);
          }
          throw error;
        }
      }
    }
  },

  async batchBridgeItems(
    { commit },
    {
      selectedTokens,
      walletPassword,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
      destinationChainId,
    }: IBatchBridgeItemsActionPayload,
  ) {
    const isItemMissingSendId: any[] = [];

    const tokens: Array<{ tokenId: string; quantity: number }> = [];
    const selectedItems: any[] = [];

    selectedTokens.forEach((item) => {
      if (!item.sendId) {
        isItemMissingSendId.push(item.name || item.uniqueInventoryPath);
      }

      if (item.selected && item.selectedQuantity && item.selectedQuantity > 0) {
        tokens.push({
          tokenId: item.sendId as string,
          quantity: item.selectedQuantity as number,
        });
        selectedItems.push(item);
      }
    });

    if (isItemMissingSendId[0]) {
      throw new Error(
        `Trying to bridge an item, ${isItemMissingSendId[0]}, that doesn't have a sendId`,
      );
    }

    const contractAddress =
      selectedTokens[0].network === ChainNetwork.ETHEREUM
        ? selectedTokens[0].ethereumContractAddress
        : undefined;

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (selectedTokens[0].network === ChainNetwork.ETHEREUM) {
        const variables = transactionHash
          ? {
              contractAddress,
              tokens,
              transactionHash,
              destinationChainId,
              totpToken: '',
              botToken,
            }
          : {
              contractAddress,
              tokens,
              totpToken,
              botToken,
              walletPassword,
              transactionFeePrice,
              destinationChainId,
            };
        const res = await client.mutate({
          mutation: batchBridgeEthTokenMutation,
          variables,
        });

        if (
          res.data &&
          res.data.batchBridgeEthToken &&
          res.data.batchBridgeEthToken.success
        ) {
          selectedItems.forEach((item) => {
            commit('updateUserItem', {
              itemUniqueInventoryPath: item.uniqueInventoryPath,
              quantity: item.selectedQuantity,
              action: 'send',
            });
          });
        }

        return res;
      }
    }
  },

  async bridgeItem(
    { commit },
    {
      item,
      quantity,
      encryptionPasscode,
      transactionFeePrice,
      totpToken,
      botToken,
      transactionHash,
      destinationChainId,
      feeTicket,
    }: IBridgeItemActionPayload,
  ) {
    if (!item.sendId) {
      throw new Error(
        `Trying to bridge an item ${item.uniqueInventoryPath} that doesn't have a sendId`,
      );
    }

    const contractAddress =
      item.network === ChainNetwork.ETHEREUM
        ? item.ethereumContractAddress
        : undefined;

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      if (item.network === ChainNetwork.ETHEREUM) {
        const res = await client.mutate({
          mutation: bridgeEthTokenMutation,
          variables: {
            contractAddress,
            tokenId: item.sendId,
            quantity,
            totpToken,
            botToken,
            transactionFeePrice,
            walletPassword: encryptionPasscode,
            transactionHash,
            destinationChainId,
          },
        });

        if (
          res.data &&
          res.data.bridgeEthToken &&
          res.data.bridgeEthToken.success
        ) {
          commit('updateUserItem', {
            itemUniqueInventoryPath: item.uniqueInventoryPath,
            quantity,
            action: 'send',
          });
        }

        return res;
      } else if (item.network === ChainNetwork.GYRI) {
        const { collection, category, type, additionalKey } =
          item.gyriTokenClassKey;
        try {
          const res = await client.mutate({
            mutation: bridgeGyriTokenMutation,
            variables: {
              tokenInstance: {
                collection,
                category,
                type,
                additionalKey,
                instance: item.fungible ? '0' : item.nonFungibleInstanceId,
              },
              quantity,
              totpToken,
              botToken,
              walletPassword: encryptionPasscode,
              bridgeFeePrice: transactionFeePrice,
              feeTicket,
            },
          });

          if (
            res.data &&
            res.data.bridgeEthToken &&
            res.data.bridgeEthToken.success
          ) {
            commit('updateUserItem', {
              itemUniqueInventoryPath: item.uniqueInventoryPath,
              quantity,
              action: 'send',
            });
          }

          return res;
        } catch (error) {
          const e = error as Error;
          if (e.message.includes('HIGH_RISK_WALLET')) {
            const message = await this.dispatch(
              'web3Wallet/handleHighRiskWalletError',
            );
            throw new Error(message);
          }
          throw error;
        }
      }
    }
  },

  async fulfillAllowance(
    { commit },
    {
      item,
      quantity,
      totpToken,
      walletPassword,
      transactionFeePrice,
    }: IFulfillAllowanceActionPayload,
  ) {
    if (
      !item.allowanceType ||
      !item.gyriTokenClassKey ||
      item.network !== 'GALACHAIN_ALLOWANCE'
    ) {
      throw new Error('Invalid item');
    }

    if (this.app.apolloProvider) {
      const client = this.app.apolloProvider.defaultClient;

      const { collection, category, type, additionalKey } =
        item.gyriTokenClassKey;

      const res = await client.mutate({
        mutation: fulfillGalaChainItemAllowanceMutation,
        variables: {
          tokenClassKey: { collection, category, type, additionalKey },
          type: item.allowanceType,
          allowanceTransferFrom: item.allowanceTransferFrom,
          quantity,
          totpToken,
          walletPassword,
          transactionFeePrice,
        },
      });

      if (
        res.data &&
        res.data.fulfillGalaChainItemAllowance &&
        res.data.fulfillGalaChainItemAllowance.success
      ) {
        commit('updateUserItem', {
          itemUniqueInventoryPath: item.uniqueInventoryPath,
          quantity,
          action: 'send',
        });
      }

      return res;
    }
  },

  async hasTransferLock({ commit }, item: UserItem) {
    if (item.network === ChainNetwork.GYRI) {
      if (this.app.apolloProvider) {
        const client = this.app.apolloProvider.defaultClient;
        const { collection, category, type, additionalKey } =
          item.gyriTokenClassKey;
        const { data } = await client.query({
          query: hasTransferLock,
          variables: {
            tokenInstance: {
              instance: item.fungible ? '0' : item.nonFungibleInstanceId,
              collection,
              category,
              type,
              additionalKey,
            },
          },
        });
        return data.hasTransferLock;
      }
    } else {
      return { locked: false };
    }
  },

  async mintFilmToken(
    { dispatch, commit },
    { quantity, transferCode, gyriId }: IMintTokenRequest & { gyriId: string },
  ) {
    try {
      const ret = await this.$filmApiService.mintFilmToken({
        quantity,
        transferCode,
      });
      const data = ret?.data?.data;
      commit('updateUsersMintTokenDetails', { data, gyriId });

      // After minting tokens, we need to fetch and update the user's wallet balances
      await dispatch('getWalletsData');
    } catch (err: any) {
      console.error(err);
      throw err;
    }
  },

  async fetchUsersMintTokenDetails(
    { dispatch, commit },
    payload: { gyriId: string } = {
      gyriId: 'FILM|Unit|none|none',
    },
  ) {
    try {
      const ret = await this.$filmApiService.fetchUsersMintTokenDetails();
      const data = ret?.data?.data;
      commit('updateUsersMintTokenDetails', { data, gyriId: payload.gyriId });
    } catch (err: any) {
      console.error(err);
    }
  },

  async fetchVestingSchedule(
    { commit },
    payload: { gyriId: string } = {
      gyriId: 'FILM|Unit|none|none',
    },
  ) {
    try {
      const ret = await this.$filmApiService.fetchVestingSchedule();

      // NOTE: API has errors so importing hardcoded schedule: 'filmTokenShedule'
      // const filmTokenShedule = getFilmTokenShedule();
      const schedule = ret?.data?.schedule || [];
      commit('updateVestingSchedule', { schedule, gyriId: payload.gyriId });
    } catch (err: any) {
      console.error(err);
    }
  },
};
