
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import TopToolbar from '~/components/Home/TopToolbar.vue';
import { IProfileState } from '~/store/profile/types';
import { TSiteConfigState } from '~/store/types';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import { TAsyncStateWithDoc } from '~/store/documents/types';
import { shouldShowUnopenedBoxesDot } from '~/utils/collections';
@Component({
  components: {
    TopToolbar,
  },
})
export default class DashboardHeader extends Vue {
  showUnopenedRevealBoxesDot = false;

  @Prop({ type: Boolean, default: false })
  readonly showWalletPrompt!: boolean;
  @Prop(Number) readonly systemBarHeight!: number;
  @Prop(Number) readonly toolbarHeight!: number;
  @Prop(Boolean) readonly uaPage!: boolean;
  @Prop(Boolean) readonly thankYouPage!: boolean;

  @State((profile: IProfileState) => profile.user.loggedIn, {
    namespace: 'profile',
  })
  loggedIn!: boolean;
  @State((profile: IProfileState) => !!profile.user.discordProfile, {
    namespace: 'profile',
  })
  discordConnected!: boolean;
  @State((share) => share.nodesOwned, { namespace: 'share' })
  readonly nodesOwned!: number;

  @Action('getUserNodes', { namespace: 'nodes' })
  private getUserNodes!: () => void;

  @Getter('siteConfig')
  readonly siteConfigState!: TSiteConfigState;

  @Getter('getUnopenedBoxesStatusForProjects', { namespace: 'collections' })
  readonly getUnopenedBoxesStatusForProjects!: () => Record<string, boolean>;

  @Getter('getProjectSlugsFromCollections', { namespace: 'collections' })
  readonly getProjectSlugsFromCollections!: string[];

  @Getter('getDocumentsBySlugs', {
    namespace: `documents.${SanityDocumentSchema.PROJECT}`,
  })
  readonly getDocumentsBySlugs!: (
    slugs: string[],
  ) => TAsyncStateWithDoc<ISanityDocuments[SanityDocumentSchema.PROJECT]>[];

  @Watch('collectionProjectSlugs', { immediate: true })
  async handleCollectionProjectsSlugsUpdate(
    projectSlugs: string[] | undefined,
  ) {
    try {
      await Promise.all(
        projectSlugs?.map((slug) =>
          this.$sanityLookup.fetchDocument({
            _type: SanityDocumentSchema.PROJECT,
            slug: slug,
          }),
        ) || [],
      );
      this.showUnopenedRevealBoxesDot = shouldShowUnopenedBoxesDot(
        this.unopenedBoxesStatusesForProjects,
        Object.values(this.projects),
      );
    } catch (error) {}
  }

  @Watch('unopenedBoxesStatusesForProjects', { immediate: true })
  async handleProjectUnopenedBoxesStatusesUpdate(
    statuses: Record<string, boolean>,
  ) {
    try {
      this.showUnopenedRevealBoxesDot = shouldShowUnopenedBoxesDot(
        this.unopenedBoxesStatusesForProjects,
        Object.values(this.projects),
      );
    } catch (error) {}
  }

  get projects() {
    return this.getDocumentsBySlugs(this.collectionProjectSlugs || []);
  }

  get collectionProjectSlugs() {
    return this.getProjectSlugsFromCollections;
  }

  get unopenedBoxesStatusesForProjects() {
    return this.getUnopenedBoxesStatusForProjects();
  }

  get siteConfig() {
    return this.$sanityLookup.getSiteConfig()?.data;
  }

  get tabs() {
    return this.siteConfigState.isLoading
      ? []
      : [
          {
            title: 'Films',
            name: this.$t('common.navigation.films') as string,
            to: '/films',
            requirement: true,
          },
          // {
          //   title: 'Nodes',
          //   name: this.$t('common.navigation.nodes') as string,
          //   to: '/nodes',
          //   requirement: !this.loggedIn,
          // },
          // {
          //   title: 'Theater',
          //   name: this.$t('common.navigation.theater') as string,
          //   to: '/theater',
          //   requirement: this.loggedIn,
          // },
          {
            title: 'Charts',
            name: this.$t('common.navigation.charts') as string,
            to: '/charts',
            requirement: true,
          },
          {
            icon: '$vuetify.icons.inventory',
            title: 'Inventory',
            name: this.$t('common.navigation.collection') as string,
            to: '/collection',
            requirement: this.loggedIn,
            includeDot: this.showUnopenedRevealBoxesDot,
          },
        ].filter((tab) => {
          return tab.requirement;
        });
  }

  async created() {
    try {
      this.getUserNodes();
    } catch (e) {
      console.error(e);
    }
  }

  get isMarketingMode() {
    return this.siteConfig?.isMarketingMode;
  }

  get isDesktopView() {
    return this.discordConnected
      ? this.$vuetify.breakpoint.width > 1035
      : this.$vuetify.breakpoint.width > 1100;
  }
}
