
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Mutation, State } from 'vuex-class';
import LoginAndRegister from '~/components/Authentication/LoginAndRegister.vue';
import ProfileProgressIcon from '~/components/Home/ProfileProgressIcon.vue';
import ReferAFriend from '~/components/Share/ReferralDialog.vue';
import PromoList from '~/components/Sanity/PromoList.vue';
import SanityReference from '~/components/Sanity/SanityReference.vue';
import { INodesState, IUserNode } from '~/store/nodes/types';
import { IUser } from '~/store/profile/types';
import PromoListItem from './PromoListItem.vue';
import QuickBalance from '~/components/Home/QuickBalance.vue';
import QuickWalletAddress from '~/components/Home/QuickWalletAddress.vue';

@Component({
  components: {
    ProfileProgressIcon,
    PromoList,
    PromoListItem,
    LoginAndRegister,
    ReferAFriend,
    SanityReference,
    QuickBalance,
    QuickWalletAddress,
  },
})
export default class ProfileNavigationDrawer extends Vue {
  @Prop(Boolean) readonly drawerControl!: boolean;
  @Prop(Boolean) readonly showTopNavbarLinks!: boolean;
  @Prop(Boolean) readonly showQuickBalance!: boolean;
  @Prop(String) readonly ethWalletAddress!: string;
  @Prop(String) readonly gyriWalletAddress!: string;

  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;
  @State((nodes: INodesState) => nodes.userNodes, { namespace: 'nodes' })
  userNodes!: IUserNode[];

  @Mutation toggleReferralDialog!: (args: any) => void;

  @Action('userLogout', { namespace: 'profile' })
  private userLogout!: () => void;

  drawer = false;
  showReferralModal = false;
  isSubMenuExpanded = false;

  mounted() {
    if (this.$refs.nav) {
      (this.$refs.nav as Vue).$el?.addEventListener('keyup', this.handleKeyup);
    }
  }

  beforeDestroy() {
    if (this.$refs.nav) {
      (this.$refs.nav as Vue).$el?.removeEventListener(
        'keyup',
        this.handleKeyup,
      );
    }
  }

  get accountMenu() {
    return [
      // {
      //   title: this.$t('common.navigation.charts'),
      //   href: '/charts',
      //   requirement: true,
      // },
      {
        title: this.$t('common.navigation.dashboard'),
        href: '/collection/dashboard',
        requirement: !!this.currentUser.loggedIn,
      },
      {
        requirement: this.currentUser.loggedIn,
        href: '/collection',
        title: this.$t('common.navigation.collection'),
      },
      {
        requirement: this.userNodes.length,
        href: '/theater',
        title: this.$t('common.navigation.theater'),
      },
      {
        requirement: this.shouldShowGetGala,
        title: this.$t('common.cta.getGala'),
        href: '/get-gala',
      },
      {
        requirement: true,
        href: '/notifications?filter=purchase',
        title: this.$t('common.misc.orderHistory'),
      },
    ];
  }

  get darkMode() {
    return this.$vuetify.theme.dark;
  }

  get themeChangeText() {
    return this.darkMode ? 'Lights On' : 'Lights Off';
  }

  get siteConfig() {
    return this.$sanityLookup.getSiteConfig()?.data;
  }

  get shouldShowGetGala() {
    return this.siteConfig?.shouldShowGetGala;
  }

  handleThemeChange() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  }

  toggleReferralModal() {
    this.toggleReferralDialog(true);
  }

  @Watch('drawerControl')
  onDialogChange(val: boolean) {
    if (!this.showTopNavbarLinks) {
      this.drawer = val;
    } else if (!val) {
      this.drawer = false;
    }
  }

  @Watch('drawer')
  onNavDrawerChange(val: boolean) {
    this.$emit('update:drawerControl', val);
    if (!val) {
      this.$emit('nav-drawer-closed');
    }
  }

  async handleLogout() {
    await this.userLogout();
  }

  handleBlur(e: Event) {
    if (!this.drawer) {
      return;
    }

    const event = e as FocusEvent;
    if (!event.relatedTarget) {
      this.drawer = false;
      return;
    }

    const hasFocus = (this.$refs.nav as Vue)?.$el?.contains(
      event.relatedTarget as Node,
    );
    if (!hasFocus) {
      this.drawer = false;
    }
  }

  handleKeyup(e: Event) {
    const event = e as KeyboardEvent;
    if (event.key === 'Escape') {
      this.drawer = false;
    }
  }
}
