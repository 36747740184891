
import '@fortawesome/fontawesome-free/css/all.css';
import { Component, Vue, Watch } from 'nuxt-property-decorator';
import createClient from '@sanity/client';
import { SanityProjectDetails } from '@sanity/image-url/lib/types/types';
import { buildLayoutMeta } from '~/utils/htmlHead';
import { State, Mutation, Action, Getter } from 'vuex-class';
import GalaFooter from '~/components/Home/GalaFooter.vue';
import DashboardHeader from '~/components/Dashboard/DashboardHeader.vue';
import DashboardMain from '~/components/Dashboard/DashboardMain.vue';
import DashboardModals from '~/components/Dashboard/DashboardModals.vue';
import DashboardSnackbar from '~/components/Dashboard/DashboardSnackbar.vue';
import DebugToolbar from '~/components/Dashboard/DebugToolbar.vue';
import { IUser } from '~/store/profile/types';
import {
  IDebugOptions,
  DEBUG_TOOL_DEFAULTS as defaultDebugToolOptions,
  IRootState,
  TClearDocumentsCachesPayload,
} from '~/store/types';
import { createBuilder, getUrlFor } from '~/plugins/sanityImage';
import { TAsyncStateWithDoc } from '~/store/documents/types';
import { getCookie } from '~/utils';
import {
  ISanityDocuments,
  SanityDocumentSchema,
} from '~/types/sanity-documents';
import { initialSiteConfig } from '~/store';
import {
  captureReferrals,
  removeStoredReferralInfo,
  createReferral,
} from '~/utils/captureReferrals';

@Component({
  head() {
    return buildLayoutMeta(this as LayoutDefault);
  },
  components: {
    DashboardHeader,
    DashboardMain,
    DashboardModals,
    DashboardSnackbar,
    GalaFooter,
    DebugToolbar,
  },
  middleware: ['checkTokenMigrationStatus', 'setDebugOverrides', 'setTheme'],
  meta: {
    theme: 'dark',
  },
})
export default class LayoutDefault extends Vue {
  @State((profile) => profile.user.loggedIn, { namespace: 'profile' })
  loggedIn!: boolean;

  @State((profile) => profile.user.walletExists, { namespace: 'profile' })
  walletExists!: boolean;

  @State((inventory) => inventory.isFetchingWallets, { namespace: 'inventory' })
  isFetchingWallets!: boolean;

  @State('debugSettings')
  readonly debugSettings!: IDebugOptions;

  @State('showHeader')
  readonly showHeader!: boolean;

  @State('showFooter')
  readonly showFooter!: boolean;

  @State((state: IRootState) => state.sanityUser)
  readonly sanityUserState!: IRootState['sanityUser'];

  @State((profile) => profile.user, { namespace: 'profile' })
  currentUser!: IUser;

  @Action('getSiteConfig')
  private getSiteConfig!: (opts: { bypassCache: true }) => void;

  @Action('fetchUserNodeLicenseCount', { namespace: 'share' })
  fetchUserNodeLicenseCount!: () => void;

  @Action('getWalletsData', { namespace: 'inventory' })
  private getWalletsData!: () => void;

  @Action('getGameTextByLocale', { namespace: 'games' })
  private getGameTextByLocale!: () => void;

  @Action('clearAllCaches')
  private clearAllCaches!: () => void;

  @Action('loadAllCollections', { namespace: 'collections' })
  private loadAllCollections!: () => void;

  @Action('getUserItems', { namespace: 'inventory' })
  private getUserItems!: () => void;

  @Mutation('updateUser', { namespace: 'profile' })
  private updateUser!: (args: Partial<IUser>) => void;

  @Getter('galaBalance', { namespace: 'inventory' })
  readonly galaBalance!: number;

  @Getter('siteConfig')
  readonly siteConfigState!: TAsyncStateWithDoc<
    ISanityDocuments[SanityDocumentSchema.SITE_CONFIG]
  >;

  @Getter('showWalletConnectionErrorBanner', { namespace: 'web3Wallet' })
  readonly showWalletConnectionErrorBanner!: boolean;

  systemBarHeight = 40;
  toolbarHeight = 70;
  key = 'init';
  debugSettingsDefault: IDebugOptions = defaultDebugToolOptions;
  docsTypes = Object.values(SanityDocumentSchema).filter(
    (key) => key !== SanityDocumentSchema.SITE_CONFIG,
  );

  uaPages: Record<string, boolean> = {
    signup: true,
    'create-account': true,
    'films-slug-signup': true,
    'films-slug-create-account': true,
    'schema-slug-signup': true,
    'schema-slug-create-account': true,
    'thank-you': true,
    'schema-slug-thank-you': true,
  };

  get isUAPage() {
    const routeName = this.$route.name;
    const path = this.$route.path;
    return (
      (!!routeName && this.uaPages[routeName]) || (!!path && this.uaPages[path])
    );
  }

  get isThankYouPage() {
    const routeName = this.$route.name;
    return routeName === 'thank-you';
  }

  get showWalletPrompt() {
    return false;
    // return (
    //   this.loggedIn &&
    //   !this.isFetchingWallets &&
    //   this.currentUser &&
    //   !this.currentUser.walletExists &&
    //   !this.currentUser.walletConnected &&
    //   !this.galaBalance &&
    //   this.$route.name !== 'connect-wallet'
    // );
  }

  beforeCreate() {
    this.$store.dispatch('getSiteConfig');
    this.$store.dispatch('getSanityUser');
  }

  async created() {
    this.listenForWalletCreated();
    this.getGameTextByLocale();
    document.addEventListener('click', this.handleGlobalClick);
  }

  destroyed() {
    document.removeEventListener('click', this.handleGlobalClick);
  }

  async mounted() {
    this.replaceOldUtmVariableStructure();
    captureReferrals(this.$route.query);

    if (this?.$route?.name !== 'inventory-symbol') {
      this.getWalletsData();
    }

    if (this.loggedIn) {
      await createReferral(this.$apolloProvider.defaultClient);
      removeStoredReferralInfo();
    }
  }

  @Watch('loggedIn', { immediate: true })
  handleLoggedInChange(current: boolean) {
    if (current) {
      this.fetchUserNodeLicenseCount();
      this.loadAllCollections();
      // this.getUserItems();
    }
  }

  @Watch('$i18n.locale')
  handleLocaleChange(current: string) {
    this.refreshData();
    this.updateKey(this.debugSettings, current);
  }

  @Watch('debugSettings')
  handleDebugSettingsChange(current: IDebugOptions, previous: IDebugOptions) {
    const hasDebugEnabledChanged =
      previous?.debugSettingsEnabled !== current?.debugSettingsEnabled;

    const hasContentSettingsChanged =
      previous?.contentDataset !== current?.contentDataset ||
      previous?.shouldShowDrafts !== current?.shouldShowDrafts;

    const hasConfigSettingsChanged =
      previous?.settingsDataset !== current?.settingsDataset ||
      previous?.shouldShowDrafts !== current?.shouldShowDrafts;

    if (
      !(
        hasDebugEnabledChanged ||
        hasContentSettingsChanged ||
        hasConfigSettingsChanged
      )
    ) {
      return;
    }

    const sanity = this.$sanity;

    if (hasDebugEnabledChanged || hasContentSettingsChanged) {
      // Update content client
      const config = {
        ...sanity.config,
        dataset: current.debugSettingsEnabled
          ? current.contentDataset
          : this.debugSettingsDefault.contentDataset,
        withCredentials: current.debugSettingsEnabled
          ? current.shouldShowDrafts
          : this.debugSettingsDefault.shouldShowDrafts,
      };
      sanity.client = createClient(config);
      sanity.config = config;

      // Update image builder
      const imgBuilder = createBuilder(config as SanityProjectDetails);
      this.$sanityImage.builder = imgBuilder;
      this.$sanityImage.urlFor = getUrlFor(imgBuilder);
    }

    if (hasDebugEnabledChanged || hasConfigSettingsChanged) {
      // Update settings client
      const settingsConfig = {
        ...sanity.settingsClient.config,
        dataset: current.debugSettingsEnabled
          ? current.settingsDataset
          : this.debugSettingsDefault.settingsDataset,
        withCredentials: current.debugSettingsEnabled
          ? current.shouldShowDrafts
          : this.debugSettingsDefault.shouldShowDrafts,
      };
      sanity.settingsClient.client = createClient(settingsConfig);
      sanity.settingsClient.config = settingsConfig;
      this.getSiteConfig({ bypassCache: true });
    }

    this.refreshData();
    this.updateKey(current, this.$i18n.locale);
  }

  get siteConfig() {
    return this.siteConfigState?.data || { ...initialSiteConfig };
  }

  get canShowDebugTool() {
    return this.siteConfig?.canShowDebugTool;
  }

  get isUsingUnsavedSettings() {
    return this.siteConfig?._id?.startsWith('drafts.');
  }

  get topGutterHeight() {
    return !this.showHeader
      ? 0
      : this.showWalletPrompt || this.showWalletConnectionErrorBanner
      ? this.toolbarHeight + this.systemBarHeight
      : this.toolbarHeight;
  }

  refreshData() {
    this.$store.dispatch({
      type: 'clearReferenceCache',
    });
    this.$store.dispatch<TClearDocumentsCachesPayload>({
      type: 'clearDocumentsCaches',
      types: this.docsTypes as SanityDocumentSchema[],
    });
    this.$nuxt.refresh();
  }

  updateKey(debugSettings: IDebugOptions, locale: string) {
    this.key =
      Object.entries(debugSettings).reduce(
        (key, [configKey, configValue]) =>
          `${key}__${configKey}_${configValue}`,
        '',
      ) + `.${locale}`;
  }

  listenForWalletCreated() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'walletCreated') {
        this.updateUser({ walletExists: true });
        this.getWalletsData();
      }
    });
  }

  replaceOldUtmVariableStructure() {
    const existingUtmVariables = localStorage.getItem('utm-variables');
    if (existingUtmVariables) {
      const utmVariables = JSON.parse(existingUtmVariables);

      if (Array.isArray(utmVariables)) {
        const [utm_campaign, utm_medium, utm_source, utm_term] = utmVariables;

        const newUtmVariables = {
          utm_campaign,
          utm_medium,
          utm_source,
          utm_term,
        };

        localStorage.setItem('utm-variables', JSON.stringify(newUtmVariables));
      } else if (
        typeof utmVariables.campaign === 'string' ||
        typeof utmVariables.medium === 'string' ||
        typeof utmVariables.source === 'string' ||
        typeof utmVariables.term === 'string'
      ) {
        const newUtmVariables = {
          utm_campaign:
            utmVariables.campaign || utmVariables.utm_campaign || '',
          utm_medium: utmVariables.medium || utmVariables.utm_medium || '',
          utm_source: utmVariables.source || utmVariables.utm_source || '',
          utm_term: utmVariables.term || utmVariables.utm_term || '',
        };
        localStorage.setItem('utm-variables', JSON.stringify(newUtmVariables));
      }
    }
  }

  get scrollbarWidth() {
    // Create a temporary div container with scrollbar and child
    const container = document.createElement('div');
    document.body.appendChild(container);
    container.style.overflow = 'scroll';
    const inner = document.createElement('div');
    container.appendChild(inner);

    // Calculate the width based on the container width minus its child width
    const width = container.offsetWidth - inner.offsetWidth;
    // Remove the container from the body
    document.body.removeChild(container);

    return width;
  }

  handleGlobalClick(event: Event) {
    const supportedTargetTypes = ['BUTTON', 'CAROUSEL'];
    const htmlElement = event.target as HTMLElement | null;
    const closestTaggedElement = htmlElement?.parentElement?.closest(
      '[data-ua-interaction-target-type]',
    ) as HTMLElement | null;
    for (let element of [htmlElement, closestTaggedElement]) {
      if (!element) {
        continue;
      }

      const interactionTargetType =
        element?.dataset.uaInteractionTargetType ?? element.tagName;
      const interactionTargetId =
        element.dataset.uaInteractionTargetId ?? element.id;

      if (!interactionTargetType || !interactionTargetId) {
        continue;
      }

      if (!supportedTargetTypes.includes(interactionTargetType)) {
        if (element.dataset.uaInteractionTargetType) {
          console.warn(
            `Unsupported UA interaction target type: ${interactionTargetType}`,
          );
        }

        continue;
      }

      this.$ua.trackPress({
        interactionTargetId: interactionTargetId,
      });
    }
  }
}
