// NOTE: API has errors so importing hardcoded schedule
export const getFilmTokenShedule = () =>
  JSON.parse(
    JSON.stringify([
      {
        date: '2024-12-05T00:00:00.000Z',
        burnToMintRatio: 0.75,
      },
      {
        date: '2024-12-06T00:00:00.000Z',
        burnToMintRatio: 0.7398550724637681,
      },
      {
        date: '2024-12-07T00:00:00.000Z',
        burnToMintRatio: 0.7297101449275363,
      },
      {
        date: '2024-12-08T00:00:00.000Z',
        burnToMintRatio: 0.7195652173913043,
      },
      {
        date: '2024-12-09T00:00:00.000Z',
        burnToMintRatio: 0.7094202898550724,
      },
      {
        date: '2024-12-10T00:00:00.000Z',
        burnToMintRatio: 0.6992753623188406,
      },
      {
        date: '2024-12-11T00:00:00.000Z',
        burnToMintRatio: 0.6891304347826087,
      },
      {
        date: '2024-12-12T00:00:00.000Z',
        burnToMintRatio: 0.6789855072463769,
      },
      {
        date: '2024-12-13T00:00:00.000Z',
        burnToMintRatio: 0.6688405797101449,
      },
      {
        date: '2024-12-14T00:00:00.000Z',
        burnToMintRatio: 0.658695652173913,
      },
      {
        date: '2024-12-15T00:00:00.000Z',
        burnToMintRatio: 0.6485507246376812,
      },
      {
        date: '2024-12-16T00:00:00.000Z',
        burnToMintRatio: 0.6384057971014493,
      },
      {
        date: '2024-12-17T00:00:00.000Z',
        burnToMintRatio: 0.6282608695652174,
      },
      {
        date: '2024-12-18T00:00:00.000Z',
        burnToMintRatio: 0.6181159420289855,
      },
      {
        date: '2024-12-19T00:00:00.000Z',
        burnToMintRatio: 0.6079710144927536,
      },
      {
        date: '2024-12-20T00:00:00.000Z',
        burnToMintRatio: 0.5978260869565217,
      },
      {
        date: '2024-12-21T00:00:00.000Z',
        burnToMintRatio: 0.5876811594202899,
      },
      {
        date: '2024-12-22T00:00:00.000Z',
        burnToMintRatio: 0.577536231884058,
      },
      {
        date: '2024-12-23T00:00:00.000Z',
        burnToMintRatio: 0.5673913043478261,
      },
      {
        date: '2024-12-24T00:00:00.000Z',
        burnToMintRatio: 0.5572463768115942,
      },
      {
        date: '2024-12-25T00:00:00.000Z',
        burnToMintRatio: 0.5471014492753623,
      },
      {
        date: '2024-12-26T00:00:00.000Z',
        burnToMintRatio: 0.5369565217391304,
      },
      {
        date: '2024-12-27T00:00:00.000Z',
        burnToMintRatio: 0.5268115942028986,
      },
      {
        date: '2024-12-28T00:00:00.000Z',
        burnToMintRatio: 0.5166666666666667,
      },
      {
        date: '2024-12-29T00:00:00.000Z',
        burnToMintRatio: 0.5065217391304347,
      },
      {
        date: '2024-12-30T00:00:00.000Z',
        burnToMintRatio: 0.4963768115942029,
      },
      {
        date: '2024-12-31T00:00:00.000Z',
        burnToMintRatio: 0.486231884057971,
      },
      {
        date: '2025-01-01T00:00:00.000Z',
        burnToMintRatio: 0.47608695652173916,
      },
      {
        date: '2025-01-02T00:00:00.000Z',
        burnToMintRatio: 0.46594202898550724,
      },
      {
        date: '2025-01-03T00:00:00.000Z',
        burnToMintRatio: 0.4557971014492754,
      },
      {
        date: '2025-01-04T00:00:00.000Z',
        burnToMintRatio: 0.44565217391304346,
      },
      {
        date: '2025-01-05T00:00:00.000Z',
        burnToMintRatio: 0.4355072463768116,
      },
      {
        date: '2025-01-06T00:00:00.000Z',
        burnToMintRatio: 0.42536231884057973,
      },
      {
        date: '2025-01-07T00:00:00.000Z',
        burnToMintRatio: 0.4152173913043478,
      },
      {
        date: '2025-01-08T00:00:00.000Z',
        burnToMintRatio: 0.40507246376811595,
      },
      {
        date: '2025-01-09T00:00:00.000Z',
        burnToMintRatio: 0.39492753623188404,
      },
      {
        date: '2025-01-10T00:00:00.000Z',
        burnToMintRatio: 0.3847826086956522,
      },
      {
        date: '2025-01-11T00:00:00.000Z',
        burnToMintRatio: 0.3746376811594203,
      },
      {
        date: '2025-01-12T00:00:00.000Z',
        burnToMintRatio: 0.3644927536231884,
      },
      {
        date: '2025-01-13T00:00:00.000Z',
        burnToMintRatio: 0.35434782608695653,
      },
      {
        date: '2025-01-14T00:00:00.000Z',
        burnToMintRatio: 0.3442028985507246,
      },
      {
        date: '2025-01-15T00:00:00.000Z',
        burnToMintRatio: 0.33405797101449275,
      },
      {
        date: '2025-01-16T00:00:00.000Z',
        burnToMintRatio: 0.3239130434782609,
      },
      {
        date: '2025-01-17T00:00:00.000Z',
        burnToMintRatio: 0.31376811594202897,
      },
      {
        date: '2025-01-18T00:00:00.000Z',
        burnToMintRatio: 0.3036231884057971,
      },
      {
        date: '2025-01-19T00:00:00.000Z',
        burnToMintRatio: 0.29347826086956524,
      },
      {
        date: '2025-01-20T00:00:00.000Z',
        burnToMintRatio: 0.2833333333333333,
      },
      {
        date: '2025-01-21T00:00:00.000Z',
        burnToMintRatio: 0.27318840579710146,
      },
      {
        date: '2025-01-22T00:00:00.000Z',
        burnToMintRatio: 0.26304347826086955,
      },
      {
        date: '2025-01-23T00:00:00.000Z',
        burnToMintRatio: 0.2528985507246377,
      },
      {
        date: '2025-01-24T00:00:00.000Z',
        burnToMintRatio: 0.2427536231884058,
      },
      {
        date: '2025-01-25T00:00:00.000Z',
        burnToMintRatio: 0.2326086956521739,
      },
      {
        date: '2025-01-26T00:00:00.000Z',
        burnToMintRatio: 0.22246376811594204,
      },
      {
        date: '2025-01-27T00:00:00.000Z',
        burnToMintRatio: 0.21231884057971015,
      },
      {
        date: '2025-01-28T00:00:00.000Z',
        burnToMintRatio: 0.20217391304347826,
      },
      {
        date: '2025-01-29T00:00:00.000Z',
        burnToMintRatio: 0.19202898550724637,
      },
      {
        date: '2025-01-30T00:00:00.000Z',
        burnToMintRatio: 0.18188405797101448,
      },
      {
        date: '2025-01-31T00:00:00.000Z',
        burnToMintRatio: 0.17173913043478262,
      },
      {
        date: '2025-02-01T00:00:00.000Z',
        burnToMintRatio: 0.16159420289855073,
      },
      {
        date: '2025-02-02T00:00:00.000Z',
        burnToMintRatio: 0.15144927536231884,
      },
      {
        date: '2025-02-03T00:00:00.000Z',
        burnToMintRatio: 0.14130434782608695,
      },
      {
        date: '2025-02-04T00:00:00.000Z',
        burnToMintRatio: 0.13115942028985508,
      },
      {
        date: '2025-02-05T00:00:00.000Z',
        burnToMintRatio: 0.12101449275362319,
      },
      {
        date: '2025-02-06T00:00:00.000Z',
        burnToMintRatio: 0.1108695652173913,
      },
      {
        date: '2025-02-07T00:00:00.000Z',
        burnToMintRatio: 0.10072463768115943,
      },
      {
        date: '2025-02-08T00:00:00.000Z',
        burnToMintRatio: 0.09057971014492754,
      },
      {
        date: '2025-02-09T00:00:00.000Z',
        burnToMintRatio: 0.08043478260869565,
      },
      {
        date: '2025-02-10T00:00:00.000Z',
        burnToMintRatio: 0.07028985507246377,
      },
      {
        date: '2025-02-11T00:00:00.000Z',
        burnToMintRatio: 0.060144927536231886,
      },
      {
        date: '2025-02-12T00:00:00.000Z',
        burnToMintRatio: 0.05,
      },
    ]),
  );
