import { Module } from 'vuex';
import { IRootState } from '~/store/types';
import { ChainNetwork } from '~/types/chain';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { IInventoryState } from './types';

export const state: IInventoryState = {
  wallets: [],
  walletsErrored: false,
  userItems: [],
  userItemsErrored: false,
  userTickets: [],
  claimFees: [],
  claimNetwork: {
    network: ChainNetwork.ETHEREUM,
    currency: 'ETH',
  },
  isFetchingWallets: false,
  mostRecentExchangeRewards: [],
  vestingScheduleByGyriId: {},
  userMintTokenDetails: {},
};

const namespaced = true;

const inventory: Module<IInventoryState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default inventory;
